<template>
  <header class="bottom_function-bar">
  </header>
</template>

<script>

export default {
  name: 'BottomBar',
  components: {
  },
  data() {
    return {
      title: ''
    }
  },
  methods: {

  }
};
</script>

<style>
.bottom_function-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 16px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

</style>
