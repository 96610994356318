<template>
<!--  <router-link to="/chat">Chat</router-link>-->
  <div className="app-container">
    <FileList/>
    <BottomBar/>
  </div>
</template>

<script>
import BottomBar from './BottomBar.vue';
import FileList from './FileList.vue';

export default {
  name: 'App',
  components: {
    FileList,
    BottomBar,
  },
  created() {
    document.title = "久知智能文档"
  },
  methods: {
  }
}
</script>

<style>

</style>
