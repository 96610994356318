import { createRouter, createWebHistory } from 'vue-router'
import Chat from '@/components/Chat.vue';
import AskDoc from "@/components/AskDoc";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: AskDoc },
        { path: '/chat', component: Chat }
    ]
})
export default router;
