<template>
  <div>
    <iframe
        ref="pdfViewer"
        :src="'pdfjs/web/viewer.html?file=' + encodeURIComponent(fileLink)"
        style="width: 100%; height: 99%"
        @load="handleIframeLoad"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    fileLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToPage(page_no) {
      const iframe = this.$refs.pdfViewer;
      var pdfWindow = iframe.contentWindow;
      pdfWindow.PDFViewerApplication.pdfViewer.gotoPage(page_no)
    },
  },
};
</script>
