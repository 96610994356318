<template>

  <div class="app-container">

    <div class="function-bar">
      <div class="function-list">
        <div class="logo">
          <img src="../assets/LOGO.png" />
        </div>
      </div>
      <div class="router_link">
        <router-link to="/">论文阅读</router-link>
        <router-link class = "router_link_a" to="/chat">智能问答</router-link>
      </div>
      <div class="function-list">
        <!--chat模型选择-->
        <div class="link">
          <el-dropdown>
                <span class="el-dropdown-link" @click="listModels('chat')">
                  {{chat_model_name}}
                  <ElIcon class="el-icon--right">
                    <ArrowDown />
                  </ElIcon>
                </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="space_info" v-for="chat_model in chat_model_list" :key="chat_model.id" @click="selectModel(chat_model)">
                  <span class="space_name_text">{{ chat_model.model_name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <a href="#" class="link" v-on:click="showImage = true">反馈</a>
        <div v-if="showImage" class="dialog">
          <img src="../assets/wx_cs.jpg">
          <button @click="showImage = false">关闭</button>
        </div>
        <!--        <a href="#" class="link" v-on:click="showImage = true">会员</a>-->
        <!--        <div v-if="showImage" class="dialog">-->
        <!--          <img src="../assets/wx_minip.jpg">-->
        <!--          <button @click="showImage = false">关闭</button>-->
        <!--        </div>-->
<!--        <a href="#" class="link" v-on:click="show_feedback = true">会员</a>-->
<!--        <div class="dialog-wrapper" v-if="show_feedback">-->
<!--          <div class="dialog-content">-->
<!--            <div class="dialog-title">开通会员</div>-->
<!--            <div class="folder_name_input">-->
<!--              <text class="name_label">请扫码进入小程序《久知智能文档》开通会员，试运行期间会员年费为99元。</text>-->
<!--              <img src="../assets/wx_minip.jpg">-->
<!--            </div>-->
<!--            <div class="button-group">-->
<!--              <button class="cancel-button" @click="show_feedback=false">关闭</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!--用户推荐面板-->
        <a href="#" class="link" v-on:click="openUserPrompt()">分享</a>
        <div class="dialog-wrapper" v-if="show_userprompt">
          <div class="dialog-content">
            <div>1. 新用户默认可以上传10篇论文，分享好友，双方可以各增加10篇额度。</div>
            <div>2. 推广大使招募中，欢迎有意向的同学通过反馈添加客服进一步沟通。</div>
            <br>
            <textarea class="feedback-content" v-model="prompt_text"></textarea>
            <div class="button-group">
              <button class="submit-button" @click="copyPromptext()">复制</button>
              <button class="cancel-button" @click="show_userprompt=false">关闭</button>
            </div>
          </div>
        </div>

        <button class="login" v-if="!isLoggedIn" @click="wxLogin">登录</button>
        <div v-else class="user-info">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{user_nick}}
              <ElIcon class="el-icon--right">
                <ArrowDown />
              </ElIcon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="logout">注销</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="app-main">

      <!--互动区域：包括问答和注释-->
      <div class="interact-container-note">
        <div class="qa-content" id="qa-content">
          <div v-if="qa_list.length === 0">
            <h3>建议大家直接访问如下问答工具：</h3>
            <p>
              <a href="https://chatglm.cn/" target="_blank">智谱清言：清华大学KEG实验室和智谱公司联合开发的大模型</a>
              <br>
              <br>
              <a href="https://yiyan.baidu.com/" target="_blank">文心一言：基于百度文心大模型</a>
              <br>
              <br>
              问答默认是基于智谱清言ChatGLM3-6b模型，会员可以选择ChatGPT模型。
            </p>
          </div>
          <div v-else>
            <div v-for= "(item, index) in qa_list" :key="index" class="qa-item">
              <div class="qa-question">{{ item.question }}</div>
              <div class="qa-answer">
                <div class="qa-answer-content">{{ item.answer }}</div>
              </div>
            </div>
          </div>
        </div>
          <!-- 加载中弹出框 -->
          <div v-if="loading" class="loading-modal">
            <div class="loading-spinner"></div>
          </div>
        <div class="qa-input-bar">
          <textarea v-model="question" class="qa-input" placeholder="请输入问题后点击问答，可通过Shift + 回车直接提问。" ref="textarea"
                    @input="resizeTextarea"
                    @keydown.enter="handleEnter" />
          <button class="qa-send-btn" @click="ask_question()">发送</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ArrowDown} from '@element-plus/icons-vue'
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon} from 'element-plus';

export default {

  name: 'FunctionBar',

  components: {
    ArrowDown,
    ElIcon,
    ElDropdown, ElDropdownMenu, ElDropdownItem,
  },

  data() {
    return {
      isLoggedIn: false,
      showImage: false,
      showMenu: false,
      showRegisterDialog: false,
      show_userprompt: false,
      show_feedback: false,
      prompt_text: '',
      code: '',
      user_nick: 'customer',
      union_id: null,
      chat_model_name: '智谱',
      chat_model_code: 'chatglm',
      chat_model_list: [],
      space_id: -1,
      file_id: '',
      keyword: '',
      default_questions: [],
      qa_list: [],
      question_list: [],
      question: '',
      loading: false,
      showTooltip: {},
      is_filelist_indent: false,
    };
  },

  created() {
    document.title = "久知智能文档"
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const channel = urlParams.get('channel')
    const channelid = urlParams.get('channelid')
    if (channel) {
      Cookies.set('channel', channel, { expires: 30 });
    }
    if (channelid) {
      Cookies.set('channelid', channelid, { expires: 30 });
    }
    // 查看是否已经登录，如果已经登录则获取用户信息
    const islogin = Cookies.get('islogin')
    if (islogin == '1') {
      this.union_id = Cookies.get('union_id')
      this.user_nick = Cookies.get('user_nick')
      this.isLoggedIn = true
      this.getUserInfo()
      // this.listquestions();
      return
    } else if (code) {
      const channel = Cookies.get('channel') || ''
      const channelid = Cookies.get('channelid') || ''
      // 调用后端服务，将 code 发送到后端
      const jsonData = {'code': code, 'channel': channel, 'channelid': channelid}
      if (code) {
        axios.post('/wechatlogin', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(response => {
          if (response.data.unionid) {
            const union_id = response.data.unionid
            const user_nick = response.data.nickname
            this.space_id = response.data.space_id
            this.union_id = union_id
            this.user_nick = user_nick
            this.isLoggedIn = true
            Cookies.set('islogin', 1, {expires: 30})
            Cookies.set('union_id', union_id, {expires: 30})
            Cookies.set('user_nick', user_nick, {expires: 30})
            this.getUserInfo()
            // this.listquestions();
            return
          }
        }).catch(error => {
          console.error(error); // 处理异步操作出现的异常
        });
      }
    }
    this.getUserInfo()
    // this.listquestions()
  },

  methods: {
    // 打开用户推荐面板
    openUserPrompt() {
      const channel = 'np';
      const channelid = this.union_id;
      // 生成推荐链接的逻辑
      const prompt_link = `https://www.jiuzhiai.cn/?channel=${channel}&channelid=${channelid}`;
      this.prompt_text = '推荐一个好用的论文阅读工具：久知智能文档。支持选词翻译，智能解读等，帮助您高效读论文！' + prompt_link;
      this.show_userprompt = true
    },

    copyPromptext() {
      const textarea = document.createElement('textarea');
      textarea.value = this.prompt_text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },

    resizeTextarea() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.value === "") {
        textarea.style.height = "2.5em"; // 设置为一行的高度
      }
    },

    async selectModel(model) {
      const jsonData = {'union_id': this.union_id, 'model_type': model.model_type, 'model_code': model.model_code}
      await axios.post('/updateusermodel', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      this.chat_model_name = model.model_name
      this.chat_model_code = model.model_code
    },

    async listModels(model_type) {
      const response = await axios.get('/listmodels', {
        params: {model_type: model_type}
      });
      if (model_type === 'chat') {
        this.chat_model_list = response.data
      }
    },

    // 扫码登录
    wxLogin() {
      const hrefUrl = 'https://www.jiuzhiai.cn'
      if (!this.code) {
        window.location.href = `
        https://open.weixin.qq.com/connect/qrconnect?appid=wx7250914a7fe08908&redirect_uri=${encodeURIComponent(hrefUrl)}&scope=snsapi_login`
      }
    },

    logout() {
      Cookies.remove('islogin');
      Cookies.remove('union_id');
      Cookies.remove('user_nick');
      this.isLoggedIn = false
      window.location.href = 'about:blank'
    },

    async getUserInfo() {
      if (!this.isLoggedIn ) {
        return
      }
      const response = await axios.get('/getuserinfo', {
        params: {union_id: this.union_id}
      })
      if (response.status === 200) {
        const chat_model_name = response.data.chat_model_name
        if (chat_model_name) {
          this.chat_model_name = chat_model_name
          this.chat_model_code = response.data.chat_model_code
        }
      }
    },

    async listquestions() {
      if (!this.isLoggedIn ) {
        return
      }
      const jsonData =
          {
            'union_id': this.union_id,
            'space_id': this.space_id,
            'page_num': 0,
          }

      const response = await axios.post('/listchatquestions', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      if (response.status === 200) {
        const qa_list = response.data
        qa_list.reverse()
        this.qa_list = qa_list
      }
    },

    async ask_question() {
      await this.chat();
      this.question = ''
      this.resizeTextarea()
    },

    // scope:0-全文内容问答，1-检索匹配后问答，2-指定内容问答
    async chat() {
      if (!this.isLoggedIn) {
        alert("请登录后使用。");
        return
      }

      const question = this.question;
      const jsonData =
          {
            'union_id': this.union_id,
            'space_id': this.space_id,
            'qa_list': this.qa_list,
            'question': question,
            'chat_model_code': this.chat_model_code
          }
      this.loading = true;

      try {
        const response = await axios.post('/chat', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 120000
        })
        const answer = response.data
        this.answer = answer;
        this.question_list.push(question);
        // 更新问答历史记录列表
        this.qa_list.push({
          question: question,
          answer: answer,
        });
        console.error("qa_list:", this.qa_list)
        this.question = ''
        const textarea = this.$refs.textarea;
        textarea.style.height = "2.5em"; // 设置为一行的高度
      } catch (error) {
        console.error(error);
      } finally {
        // 设置loading状态为false
        this.loading = false;
        this.scrollToBottomAndCenter()
      }
    },


    handleEnter(event) {
      if (event.shiftKey) {
        this.chat();
        // this.question += '\n';
      } else {
        // this.question += '\n';
        // this.askDoc(1);
      }
    },

    scrollToBottomAndCenter() {
      const parentElement = document.getElementById('qa-content');
      if (parentElement && parentElement.id === 'qa-content') {
        parentElement.scrollTop = parentElement.scrollHeight;
      }
    },
  },

};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.function-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  padding: 0 16px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.app-main {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1px;
  height: 95%;
}

.dialog-wrapper {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.dialog-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.cancel-button,
.submit-button {
  margin-left: 10px;
  margin-right: 10px;
  font-size: x-small;
  width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
}

.cancel-button {
  background-color: #ccc;
}

.submit-button {
  background-color: #409eff;
  color: #fff;
}

.feedback-content {
  width: 95%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  margin-bottom: 10px;
}


.logo {
  display: flex;
}

.logo img {
  height: 80px;
  margin-right: 8px;
}

.function-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.link {
  padding-right: 15px;
}


.space_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.space_name_text {
  font-size: 14px;
  color: black;
  margin-right: 10px;
  flex-grow: 1;
}

.space_actions {
  font-size: 12px;
  color: gray;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
}

.el-icon--right {
  height: 20px;
  cursor: pointer;
}

.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* 白色背景，透明度为 80% */
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.dialog img {
  max-width: 50%;
  max-height: 50%;
  padding: 10px;
}


.function-bar h1 {
  font-size: 24px;
  font-weight: normal;
  margin-left: 10px;
}

.login button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.user-info span {
  font-size: 16px;
}


.interact-container-note {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 68%;
  height: 99%;
  align-items: center;
  padding: 1px;
  border-radius: 4px;
}


.qa-content {
  overflow-y: auto;
  width: 98%;
  height: 88%;
  border-radius: 4px;
}


.qa-item {
  margin-bottom: 10px;
}

.qa-question {
  /*padding: 5px;*/
  font-weight: normal;
  white-space: pre-wrap;
  line-height: 1.5;
}

.qa-answer {
  padding: 5px;
  font-weight: lighter;
  white-space: pre-wrap;
  line-height: 1.5;
  background-color: #f5f5f5;
}

.qa-answer-content {
  padding-bottom: 5px;
}

.qa-input-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:60%;
  position: fixed;
  bottom: 10px;
  /*padding: 10px;*/
  margin-bottom: 10px;
  border-radius: 5px;
}

.qa-input {
  width: 100%;
  /*height: 2.5em;*/
  font-size: large;
  font-weight: lighter;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.qa-send-btn {
  font-size: smaller;
  margin-left: 5px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.loading-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
  animation: loading-spinner 0.8s ease-in-out infinite;
}

@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}


.indent-tooltip {
  position: absolute;
  font-size: xx-small;
  top: 15%;
  left: 15%;
  /*transform: translateX(-50%);*/
  z-index: 9999;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}


.router_link {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.router_link_a {
  pointer-events: none;
  color: #000;
  text-decoration: none;
}
</style>
