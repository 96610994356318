import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';
import router from './router'

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

// axios.defaults.baseURL = 'http://localhost:5050';
// axios.defaults.baseURL = 'http://192.168.1.75:5050';
axios.defaults.baseURL = 'https://server.jiuzhiai.cn:5000';

const app = createApp(App)
app.use(VueMarkdownEditor);
app.use(router);
app.mount('#app')
