<template>

  <div class="app-container">

    <header v-if="!is_filelist_indent"  class="function-bar">
      <link rel="preload" href="pdfjs/build/pdf.worker.js" as="script">
      <div class="function-list">
        <div class="logo">
          <img src="../assets/LOGO.png" alt="Logo" />
        </div>
        <div v-if="isLoggedIn" class="space_info">
<!--        <div class="space_info">-->
          <div class="space_info">
            <el-dropdown>
                <span class="el-dropdown-link" @click="listSpaces">
                  {{space_name}}
                  <ElIcon class="el-icon--right">
                    <ArrowDown />
                  </ElIcon>
                </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="space in self_space_list" :key="space.space_id" @click="selectSpace(space)">
                    {{ space.space_name }}<br>
                  </el-dropdown-item>
                  <hr v-if="share_space_list.length > 0" class="horizontal-line">
                  <el-dropdown-item class="space_info" v-for="space in share_space_list" :key="space.space_id" @click="selectSpace(space)">
                      <span class="space_name_text">{{ space.space_name }}</span>
                      <div v-if="space.current_access==='1'" class="space_actions">
                        <el-dropdown class="space_actions" size="small">
                          <span class="el-dropdown-link">
                            <el-icon class="el-icon--right">
                              <ArrowDown />
                            </el-icon>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item @click="showUpdateSpace(space)">编辑</el-dropdown-item>
                              <el-dropdown-item @click="deleteSpace(space)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                  </el-dropdown-item>
                  <hr class="horizontal-line">
                  <el-dropdown-item @click="isAddingSpace=true">新增空间</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>


            <!--新建或者更新空间-->
            <div class="dialog-wrapper" v-if="isAddingSpace">
              <div class="dialog-content">
                <div class="dialog-title">新建/编辑空间</div>
                <div class="folder_name_input">
                  <text class="name_label">空间名称：</text>
                  <input class="input_field" v-model="new_space_name" placeholder="请输入空间名称">
                </div>
                <div class="button-group">
                  <button class="cancel-button" @click="isAddingSpace=false">取消</button>
                  <button class="submit-button" @click="upsertSpace">提交</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="router_link">
        <router-link class = "router_link_a" to="/">论文阅读</router-link>
        <router-link to="/chat">智能问答</router-link>
      </div>

      <!--      <h1>久知智能文档</h1>-->
<!--      <div v-if="file_id" class="qa-file-name">{{ file_name }}</div>-->
      <div class="function-list">
        <!--chat模型选择-->
        <div class="link">
          模型选择：
          <el-dropdown>
                <span class="el-dropdown-link" @click="listModels('chat')">
                  {{chat_model_name}}
                  <ElIcon class="el-icon--right">
                    <ArrowDown />
                  </ElIcon>
                </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="space_info" v-for="chat_model in chat_model_list" :key="chat_model.id" @click="selectModel(chat_model)">
                  <span class="space_name_text">{{ chat_model.model_name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <a href="#" class="link" v-on:click="showImage = true">反馈</a>
        <div v-if="showImage" class="dialog-wrapper">
          <div class="dialog-content">
<!--            <div class="dialog-title">反馈</div>-->
            <div class="img_wxminip">
              <text class="name_label">有任何问题或建议，请扫码添加客服微信进行反馈。</text>
              <img class="img_wx" src="../assets/wx_cs.jpg">
            </div>
            <div class="button-group">
              <button class="cancel-button" @click="showImage=false">关闭</button>
            </div>
          </div>
        </div>
<!--        <a href="#" class="link" v-on:click="showImage = true">会员</a>-->
<!--        <div v-if="showImage" class="dialog">-->
<!--          <img src="../assets/wx_minip.jpg">-->
<!--          <button @click="showImage = false">关闭</button>-->
<!--        </div>-->
<!--        <a href="#" class="link" v-on:click="show_feedback = true">会员</a>-->
<!--        <div class="dialog-wrapper" v-if="show_feedback">-->
<!--          <div class="dialog-content">-->
<!--            <div class="dialog-title">开通会员</div>-->
<!--            <div class="img_wxminip">-->
<!--              <text class="name_label">所有功能免费，满足核心需求。如需最新的chatgpt等商用模型才需要开通会员。</text>-->
<!--&lt;!&ndash;              <img src="../assets/wx_minip.jpg">&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="button-group">-->
<!--              <button class="cancel-button" @click="show_feedback=false">关闭</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!--用户推荐面板-->
        <a href="#" class="link" v-on:click="openUserPrompt()">分享</a>
        <div class="dialog-wrapper" v-if="show_userprompt">
          <div class="dialog-content">
            <div>免费的智能论文阅读软件，欢迎推荐给朋友同学使用。</div>
            <br>
            <textarea class="feedback-content" v-model="prompt_text"></textarea>
            <div class="button-group">
              <button class="submit-button" @click="copyPromptext()">复制</button>
              <button class="cancel-button" @click="show_userprompt=false">关闭</button>
            </div>
          </div>
        </div>

        <button class="login" v-if="!isLoggedIn" @click="wxLogin">登录</button>
        <div v-else class="user-info">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{user_nick}}
              <ElIcon class="el-icon--right">
                <ArrowDown />
              </ElIcon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="logout">注销</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </header>

    <div class="app-main">
      <div v-if="!is_filelist_indent" class="left-panel-container">
        <div v-if="!file_id" class="file-list-container">
          <div class="file-list-bar">
            <div class="file-title">文件夹</div>
            <el-icon class="add-folder-icon" @click="this.isAddingFolder = true"><FolderAdd /></el-icon>
            <div class="function-name" @click="backParentFolder()">返回</div>

            <ElIcon class="indent-button" @click="this.is_filelist_indent = true, showTooltip[0] = null"
                    @mouseover="showTooltip[0] = 2"
                    @mouseleave="showTooltip[0] = null"><DArrowLeft /></ElIcon>
            <span class="indent-tooltip" v-if="showTooltip[0] === 2">缩进</span>

          </div>

          <div class="folder-list">
            <hr class="file-horizontal-line">
            <div v-for="(file, index) in folder_list" :key="index" class="folder-info">
              <div class="folder-name" @click="openFolder(file)">
                <el-icon><Folder /></el-icon>
                {{ file.file_name }}
              </div>
              <div class="file-action-row">
                <el-dropdown size="small">
                <span class="el-dropdown-link">
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="showUpdateFolder(file)">修改</el-dropdown-item>
                      <el-dropdown-item @click="deleteFile(file.file_id)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
            <!--          <hr class="file-horizontal-line">-->

          </div>
          <div class="file-upload-area">
            <div class="file-upload">
              <input type="file" ref="fileInput" style="display: none" @change="addFile('0')" />
              <div class="drag-drop-area" @dragover="handleDragOver" @drop="handleDrop">
                <p>拖拽文件到此区域进行上传</p>
              </div>
              <button @click="checkLoginAndUpload()">点击上传文件</button>
            </div>
          </div>
          <!--新建文件夹-->
          <div class="dialog-wrapper" v-if="isAddingFolder">
            <div class="dialog-content">
              <div class="dialog-title">新建文件夹</div>
              <div class="folder_name_input">
                <text class="name_label">文件夹名称：</text>
                <input class="input_field" v-model="new_folder_name" placeholder="请输入文件夹名称">
              </div>
              <div class="button-group">
                <button class="cancel-button" @click="isAddingFolder=false">取消</button>
                <button class="submit-button" @click="addFile('1')">提交</button>
              </div>
            </div>
          </div>
          <!--编辑文件夹-->
          <div class="dialog-wrapper" v-if="isUpdatingFolder">
            <div class="dialog-content">
              <div class="dialog-title">编辑文件夹</div>
              <div class="folder_name_input">
                <text class="name_label">文件夹名称：</text>
                <input class="input_field" v-model="new_folder_name" placeholder="请输入文件夹名称">
              </div>
              <div class="button-group">
                <button class="cancel-button" @click="isUpdatingFolder=false">取消</button>
                <button class="submit-button" @click="updateFolder()">提交</button>
              </div>
            </div>
          </div>
        </div>
        <!-- 单个文件展示章节目录-->
        <div v-if="file_id" class="file-list-container">
          <div class="file-list-bar">
            <div class="file-title">章节目录</div>
            <div class="function-name" @click="clearFileDetails()">返回</div>
            <ElIcon class="indent-button" @click="this.is_filelist_indent = true"><DArrowLeft /></ElIcon>
          </div>

          <div class="folder-list">
            <hr class="file-horizontal-line">
            <div v-for="(title, index) in agenda" :key="index" :class="'agenda-info-' + title.level">
              <div class="agenda-name" @click="gotoPage(title)">
                {{ title.text }}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div v-else class="file-list-container-indent">
        <div class="file-list-bar">
          <ElIcon  class="indent-button" @click="this.is_filelist_indent = false"><DArrowRight /></ElIcon>
        </div>
      </div>

      <!--中间区域，展示文件列表或者文件pdf阅读面板-->
      <div class="middle-area" :class="{ expand: is_filelist_indent }">
        <div v-if="file_id" class="file-reader">
          <PdfViewer ref="pdfViewer" :fileLink="pdf_link ? pdf_link : file_link" style="width: 99%; height:99%"></PdfViewer>
        </div>
        <div class="file-info-area">
            <div v-for="(file, index) in file_list" :key="index" class="file-info">
              <div class="file-name" @click="getFileDetails(file.file_id)">
                <el-icon><Document /></el-icon>
                {{ file.file_name }}
              </div>
              <div class="file-actions">
                <div class="file-date">{{ file.update_date }}</div>
                <div class="file-dropdown">
                  <el-dropdown size="small">
              <span class="el-dropdown-link">
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="deleteFile(file.file_id)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>

      </div>

      <!--互动区域：包括问答和注释-->
      <div class="right_panel">
        <div v-if="file_id" class="right_panel_file">
          <div class="interact-container" id="qacontent">

            <div v-if="file_id" class="action-scope">
              <ElRadioGroup size="small" v-model="interact_type">
                <ElRadioButton :label="0">问答</ElRadioButton>
                <ElRadioButton :label="1">笔记</ElRadioButton>
              </ElRadioGroup>
              <div class="file_action">
                <div class="link_action" >
                  原文:
                  <a href="#blank" class="link_action" @click="previewDocument(file_link)">预览</a>
                  <a v-bind:href="file_link" v-bind:download="file_name" class="link_action">下载</a>
                </div>
                <!--            <div v-if="word_link" class="link_action" >-->
                <!--              Word:-->
                <!--              <a href="#blank" class="link_action" @click="previewDocument(word_link)">预览</a>-->
                <!--              <a v-bind:href="word_link" class="link_action">下载</a>-->
                <!--            </div>-->
                <!--            <div v-if="excel_link" class="link_action" >-->
                <!--              Excel:-->
                <!--              <a href="#blank" class="link_action" @click="previewDocument(excel_link)">预览</a>-->
                <!--              <a v-bind:href="excel_link" class="link_action">下载</a>-->
                <!--            </div>-->
              </div>
            </div>
            <div v-if="interact_type==1" class="interact-container-note">
              <div class="file-note" >
                <v-md-editor height="100%"
                             left-toolbar="h bold italic ul ol table save"
                             right-toolbar=""
                             @save="saveNote"
                             v-model="note" mode="edit"></v-md-editor>
              </div>
            </div>
            <div v-else class="interact-container-note"  >
              <div class="qa-content">
                  <div v-if="file_id" class="file_summary">
                    <div >{{ summary }}</div>
                    <a href="#blank" v-if="!default_questions" @click="getFileDetails(file_id)">刷新</a>
                  </div>

                  <div  v-if="file_id && default_questions" class="tips_title">问题示例：</div>
                  <div class="default_question_bar" v-for="(question, index) in default_questions" :key="index">
                    <a href="#blank" class="default_question_bar" @click="ask_default_question(question)">{{ question }}</a>
                  </div>
                  <div v-for= "(item, index) in qa_list" :key="index" class="qa-item">
                    <div class="qa-question">{{ item.question }}</div>
                    <div class="qa-answer">
                      <div class="qa-answer-content">{{ item.answer.content }}</div>
                      <div class="reference-list">
                        <div v-if="item.answer.paragraphs && item.answer.paragraphs.length > 0">参考：</div>
                        <ul>
                          <li v-for="paragraph in item.answer.paragraphs" :key="paragraph.index">
                            <a v-if="paragraph.position" href="#" class="reference-item"
                               @mouseover="showTooltip[index] = paragraph.position"
                               @mouseleave="showTooltip[index] = null"
                               @click="goToFile(paragraph)"
                            >
                              {{ file_id ? 'page_' + paragraph.position.split('_')[1] : paragraph.file_name.substring(0, 10) }}
                              <span class="tooltip" v-if="showTooltip[index] === paragraph.position">{{ paragraph.text }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!--选择文本功能区-->
                  <div class="selection-area" v-if="selected_text">
                    <ElRadioGroup class="selection-area-bar" size="small" v-model="selection_action" @change="handleSelectionChange">
                      <ElRadioButton :label="1">翻译</ElRadioButton>
                      <ElRadioButton :label="2">问答</ElRadioButton>
                      <ElRadioButton :label="3">摘要</ElRadioButton>
                      <ElRadioButton :label="0">笔记</ElRadioButton>
                    </ElRadioGroup>
        <!--              <div class="selected-text-area-text">{{ selected_text }}</div>-->
                    <textarea class="selected-text-area" v-model="selected_text"></textarea>
                    <div class="selected-text-result_area">
                      <div v-if="selection_action==1">
                        <a href="#blank" class="selected-text-result" @click="manual_translate">点击翻译</a>
                        <div class="speak-url" v-if="phonetic && speak_url" >
                          <div class="phonetic" >/ {{phonetic}} /</div>
                          <audio ref="audio" :src="speak_url"></audio>
                          <ElIcon class="phonetic" @click="playAudio"><VideoPlay/></ElIcon>
                        </div>
                        <div class="selected-text-result" v-for="item in selected_text_result" :key="item?.id || 'defaultKey'">
                          {{ item }}</div>
                      </div>
                      <a href="#blank" v-if="selection_action==0" class="selected-text-result" @click="addNote">点击添加为笔记</a>
                      <a href="#blank" v-if="selection_action==2" class="selected-text-result" @click="selected_ask('对内容进行解读，用中文回答')">点击对选中内容进行解读，用中文回答</a>
                      <div v-if="selection_action==2" class="selected-text-result">{{ selected_text_explain }}</div>
                      <a href="#blank" v-if="selection_action==3" class="selected-text-result" @click="selected_summary">点击对选中内容生成摘要</a>
                      <div v-if="selection_action==3" class="selected-text-result">{{ selected_text_summary }}</div>
                    </div>

                  </div>
              </div>
              <!-- 加载中弹出框 -->
              <div v-if="loading" class="loading-modal">
                <div class="loading-spinner"></div>
              </div>
            </div>
          </div>
          <div  class="qa-input-area">
            <div class="qa-scope">
              <ElRadioGroup size="small" v-model="scope">
                <ElRadioButton :label="0"
                               @mouseover="showTooltip[0] = 0"
                               @mouseleave="showTooltip[0] = null">全文问答
                </ElRadioButton>
                <ElRadioButton :label="1"
                               @mouseover="showTooltip[0] = 1"
                               @mouseleave="showTooltip[0] = null">检索问答</ElRadioButton>
              </ElRadioGroup>
<!--              <span class="scope-tooltip" v-if="showTooltip[0] === 0">基于文档全文进行问答，对于大文档，会先提取文档摘要再结合内容回答</span>-->
<!--              <span class="scope-tooltip" v-if="showTooltip[0] === 1">对文档内容进行关键词匹配检索后问答，用于针对文章内容的提问</span>-->
            </div>
            <div class="qa-input-bar">
                <textarea v-model="question" class="qa-input" placeholder="请输入问题，shift + 回车换行。" ref="textarea"
                          @input="resizeTextarea"
                          @keydown.enter="handleEnter" />
              <button class="qa-send-btn" @click="ask_question()">发送</button>
            </div>
          </div>
        </div>
        <div v-else class="intro-container">
          <div class="intro2"><i class="fas fa-book"></i>文档上传<br>点击左侧按钮或者拖动文件进行上传</div>
          <div class="intro1"><i class="fas fa-language"></i>选词翻译<br>选词选句自动翻译</div>
          <div class="intro2"><i class="fas fa-book"></i>内容解读<br>选择内容后通过大模型进行智能解读</div>
          <div class="intro3"><i class="fas fa-question-circle"></i>检索问答<br>精准，基于文档内容进行检索后智能问答</div>
          <div class="intro4"><i class="fas fa-question-circle"></i>全文问答<br>模糊，对文档进行摘要后进行智能问答</div>
          <!--          <div class="intro5"><i class="fas fa-cog"></i>知识库管理<br>登录后可以创建新的知识库，对应不同部门或者主题</div>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ArrowDown, Folder, FolderAdd, Document, VideoPlay, DArrowLeft, DArrowRight} from '@element-plus/icons-vue'
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon, ElRadioGroup, ElRadioButton} from 'element-plus';
import PdfViewer from "./PdfViewer"

export default {

  name: 'FunctionBar',

  components: {
    ElRadioGroup, ElRadioButton, VideoPlay,
    PdfViewer,
    ArrowDown, DArrowLeft, DArrowRight,
    ElIcon, Folder, Document, FolderAdd,
    ElDropdown, ElDropdownMenu, ElDropdownItem,
  },

  data() {
    return {
      isLoggedIn: false,
      showImage: false,
      showMenu: false,
      showRegisterDialog: false,
      show_userprompt: false,
      prompt_text: '',
      show_feedback: false,
      feedback_content: '',
      feedback_contact: '',
      code: '',
      user_nick: 'customer',
      union_id: null,
      chat_model_name: '智谱',
      chat_model_code: 'chatglm',
      chat_model_list: [],
      space_id: '',
      space_name: '个人空间',
      new_space_: '',
      new_space_id: '',
      new_space_name: '',
      isAddingSpace: false,
      self_space_list: [],
      share_space_list: [],
      folder_id: '0',
      folder_name: '',
      current_path: [],
      update_folder_id: '',
      new_folder_name: '',
      isUpdatingFolder: false,
      isAddingFolder: false,
      file_list: [],
      folder_list: [],
      file_type: '0',
      selectedFileName: '',
      file_id: '',
      agenda: [],
      file_link: '',
      pdf_link: '',
      word_link: '',
      excel_link: '',
      file_name: '',
      keyword: '',
      summary: '请点击文件名称以查看文档摘要以及智能生成的问题。',
      default_questions: [],
      qa_list: [],
      question_list: [],
      question: '',
      loading: false,
      showTooltip: {},
      selection_action: 1,
      selected_text: '',
      speak_url: '',
      phonetic: '',
      selected_text_result: [],
      selected_text_summary: '',
      selected_text_explain: '',
      is_filelist_indent: false,
      need_manual_translate: false,
      scope: 0,
      interact_type: 0,
      note: '',
      saveTimer: null,
      isEventListenerAdded: false
    };
  },

  watch: {
    note: {
      handler: function(newNote) {
        // 取消之前的定时器
        clearTimeout(this.saveTimer);
        // 设置新的定时器
        this.saveTimer = setTimeout(() => {
          this.saveNote(newNote);
        }, 3000); // 3秒后保存数据
      },
      deep: true
    }
  },

  created() {
    document.title = "久知智能文档"
    if (!this.isEventListenerAdded) {
      window.addEventListener("message", this.handleMessage);
      this.isEventListenerAdded = true;
    }
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const channel = urlParams.get('channel')
    const channelid = urlParams.get('channelid')
    if (channel) {
      Cookies.set('channel', channel, { expires: 30 });
    }
    if (channelid) {
      Cookies.set('channelid', channelid, { expires: 30 });
    }
    // 查看是否已经登录，如果已经登录则获取用户信息
    const islogin = Cookies.get('islogin')
    if (islogin == '1') {
      this.union_id = Cookies.get('union_id')
      this.user_nick = Cookies.get('user_nick')
      this.isLoggedIn = true
      this.getUserInfo()
      this.listFiles();
      return
    } else if (code) {
      const channel = Cookies.get('channel') || ''
      const channelid = Cookies.get('channelid') || ''
      // 调用后端服务，将 code 发送到后端
      const jsonData = {'code': code, 'channel': channel, 'channelid': channelid}
      if (code) {
        axios.post('/wechatlogin', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(response => {
          if (response.data.unionid) {
            const union_id = response.data.unionid
            const user_nick = response.data.nickname
            this.space_id = response.data.space_id
            this.union_id = union_id
            this.user_nick = user_nick
            this.isLoggedIn = true
            Cookies.set('islogin', 1, {expires: 30})
            Cookies.set('union_id', union_id, {expires: 30})
            Cookies.set('user_nick', user_nick, {expires: 30})
            this.getUserInfo()
            this.listFiles();
            return
          }
        }).catch(error => {
          console.error(error); // 处理异步操作出现的异常
        });
      }
    }
    // this.setTempUnionId()
    this.getUserInfo()
    this.listFiles()
  },
  methods: {
    goToChat() {
      this.$router.push('/chat');
    },

    gotoPage(title) {
      var page_no = title.page_no
      console.error("page_no", page_no)
      this.$refs.pdfViewer.goToPage(page_no)
    },

    playAudio() {
      this.$refs.audio.play()
    },

    handleSelectionChange() {
      if (this.selection_action === 1) {
        this.translate();
      }
    },
    // 打开用户推荐面板
    openUserPrompt() {
      const channel = 'np';
      const channelid = this.union_id;
      // 生成推荐链接的逻辑
      const prompt_link = `https://www.jiuzhiai.cn/?channel=${channel}&channelid=${channelid}`;
      this.prompt_text = '推荐一个好用的论文阅读工具：久知智能文档。支持选词翻译，智能解读等，帮助您高效读论文！' + prompt_link;
      this.show_userprompt = true
    },
    copyPromptext() {
      const textarea = document.createElement('textarea');
      textarea.value = this.prompt_text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
    closeUserPrompt() {
      this.show_userprompt = false;
    },

    async addFeedback() {
      if (!this.feedback_content) {
        return; // 如果反馈内容为空，则跳出函数
      }

      const jsonData = {
        union_id: this.union_id,
        user_nick: this.user_nick,
        feedback_content: this.feedback_content,
        feedback_contact: this.feedback_contact,
      };
      try {
        await axios.post('/addfeedback', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
      } catch (error) {
        console.error(error);
      }
      this.show_feedback = false
    },

    async saveNote() {
      const jsonData = {
        union_id: this.union_id,
        space_id: this.space_id,
        file_id: this.file_id,
        note: this.note,
      };
      try {
        await axios.post('/updatenote', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async addNote() {
      this.note = this.note + '\n\n' + this.selected_text
    },

    manual_translate() {
      this.translate();
      this.need_manual_translate = false
    },

    handleMessage(event) {
      // this.scrollToBottomAndCenter();
      const event_data = event.data
      if (event_data && event_data.type === 'textSelection') {
        const selectedText = event_data.text;
        // this.selected_text = selectedText.replace(/\n/g, '')
        this.selected_text = selectedText
        // console.error("selected:", this.selected_text)

        this.selected_text_result = []
        this.speak_url = ''
        this.phonetic = ''
        this.selected_text_summary = ''
        this.selected_text_explain = ''
        this.need_manual_translate = false
        if (selectedText) {
          // const filteredText = selectedText.replace(/[^\u4e00-\u9fa5a-zA-Z\s]/g, '').trim();
          const filteredText = selectedText.trim();
          if (filteredText && /[^\s]/.test(filteredText)) {
            // this.selected_text = filteredText.replace(/\n/g, '');
            if (filteredText.length < 200) {
              if (this.selection_action === 1) {
                  this.translate();
              }
            } else {
              this.need_manual_translate = true
            }
          }
        }
      }
      this.scrollToBottomAndCenter();
    },

    debounce(func, wait) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, wait);
    },

    async translate() {
      this.debounce(this.translate_now, 300);
    },

    async translate_now() {
      const jsonData = {
        union_id: this.union_id,
        space_id: this.space_id,
        file_id: this.file_id,
        selected_text: this.selected_text
      };
      // this.loading = true;
      try {
        const response = await axios.post('/translate', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        this.selected_text_result = response.data.content;
        this.speak_url = response.data.speak_url
        this.phonetic = response.data.phonetic
        // this.qa_list.push({
        //   question: this.selected_text,
        //   answer: {
        //     content: response.data.content,
        //   },
        // });
        this.scrollToBottomAndCenter();
      } catch (error) {
        console.error(error);
      }
      this.need_manual_translate = false
      this.loading = false;
    },

    async showAddFolder() {
      this.isAddingFolder = true
    },

    async showUpdateFolder(file) {
      this.isUpdatingFolder = true
      this.update_folder_id = file.file_id
      this.new_folder_name = file.file_name
    },

    openFolder(file) {
      this.folder_id = file.file_id
      this.folder_name = file.file_name
      this.current_path.push({"file_id": file.file_id, "file_name": file.file_name})
      this.listFiles(file.file_id);
    },

    backParentFolder() {
      if (!this.current_path.length) { // 检查是否已经在根目录或传入的文件夹没有父级文件夹
        return;
      }
      if (this.current_path.length < 2) {
        this.folder_id = '0';
      } else {
        const parent_folder = this.current_path[this.current_path.length - 1]; // 获取当前路径中的上一级文件夹
        this.folder_id = parent_folder.file_id;
        this.folder_name = parent_folder.file_name;
      }
      this.current_path.pop(); // 删除最后一项（当前文件夹）
      this.listFiles(this.folder_id); // 列出上一级文件夹的内容

    },

    updateFolder() {
      var jsonData = {
        'union_id': this.union_id, 'space_id': this.space_id,
        'file_id': this.update_folder_id, 'file_name': this.new_folder_name
      };

      axios.post('/updatefolder', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      this.isUpdatingFolder = false
      this.listFiles()
    },

    goBack() {
      if (this.current_path.length >= 2) {
        // console.error("this.current_path", this.current_path)
        this.current_path.pop()
        const file = this.current_path[this.current_path.length - 1]
        this.folder_id = file.file_id
        this.folder_name = file.file_name
      } else {
        if (this.current_path.length == 1) {
          this.current_path.pop()
        }
        this.folder_id = '0'
        this.folder_name = ''
      }

      this.listFiles(this.folder_id);
    },

    resizeTextarea() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.value === "") {
        textarea.style.height = "1.5em"; // 设置为一行的高度
      }
    },

    previewDocument(link) {
      const url = btoa(link)
      var preview_url = 'https://server.jiuzhiai.cn:8088/onlinePreview?url=' + encodeURIComponent(url)
          + '&officePreviewType=pdf'

      window.open(preview_url);
    },

    setTempUnionId() {
      const has_temp_unionid = Cookies.get('has_temp_unionid')
      if (has_temp_unionid == 1) {
        this.union_id = Cookies.get('temp_union_id')
      } else {
        let temp_union_id = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length
        for (let i = 0; i < 32; i++) {
          temp_union_id += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        this.union_id = temp_union_id
        Cookies.set('has_temp_unionid', 1, {expires: 30})
        Cookies.set('temp_union_id', temp_union_id, {expires: 30})
      }
    },

    async upsertSpace() {
      const jsonData = {'union_id': this.union_id, 'space_id': this.new_space_id, 'space_name': this.new_space_name}
      const response = await axios.post('/upsertspace', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      this.isAddingSpace = false
      this.new_space_id = ''
      this.space_id = response.data.space_id
      this.space_name = response.data.space_name
      this.listFiles()
    },

    async deleteSpace(space) {
      // console.error("deleteSpace", space)
      const jsonData = {'union_id': this.union_id, 'space_id': space.space_id}
      await axios.post('/deletespace', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      this.listSpaces()
    },

    async showUpdateSpace(space) {
      this.new_space_id = space.space_id
      this.new_space_name = space.space_name
      this.isAddingSpace = true
    },

    async listSpaces() {
      if (!this.isLoggedIn ) {
        return
      }
      const response = await axios.get('/listspaces', {
        params: {union_id: this.union_id}
      });
      const all_space = response.data
      this.self_space_list = []
      this.share_space_list = []

      if (all_space.length > 0) {
        this.space_id = all_space[0]['space_id'];
        this.space_name = all_space[0]['space_name'];
      } else {
        this.space_id = '';
        this.space_name = '个人空间';
      }

      for (let i = 0; i < all_space.length; i++) {
        const record = all_space[i];
        if (record.is_default === '1') {
          this.space_id = record.space_id;
          this.space_name = record.space_name;
        }

        // 判断 space_type=0 的记录
        if (record.space_type === '0') {
          this.self_space_list.push(record);
        }

        // 判断 space_type=1 的记录
        if (record.space_type === '1') {
          this.share_space_list.push(record);
        }
      }

      const space_response = await axios.get('/getspacedetails', {
        params: {union_id: this.union_id, space_id: this.space_id}
      });
      this.qa_list = space_response.data.qa_list

    },

    async selectSpace(space) {
      const jsonData = {'union_id': this.union_id, 'space_id': space.space_id}
      await axios.post('/selectspace', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      this.space_id = space.space_id
      this.space_name = space.space_name
      this.listFiles()
      this.clearFileDetails()
    },

    async selectModel(model) {
      const jsonData = {'union_id': this.union_id, 'model_type': model.model_type, 'model_code': model.model_code}
      const response = await axios.post('/updateusermodel', jsonData, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200) {
        this.chat_model_name = model.model_name
        this.chat_model_code = model.model_code
      } else {
        alert(response.data)
      }
    },

    async listModels(model_type) {
      const response = await axios.get('/listmodels', {
        params: {model_type: model_type}
      });
      if (model_type === 'chat') {
        this.chat_model_list = response.data
      }
    },

    // 扫码登录
    wxLogin() {
      const hrefUrl = 'https://www.jiuzhiai.cn'
      if (!this.code) {
        window.location.href = `
        https://open.weixin.qq.com/connect/qrconnect?appid=wx7250914a7fe08908&redirect_uri=${encodeURIComponent(hrefUrl)}&scope=snsapi_login`
      }
    },

    logout() {
      Cookies.remove('islogin');
      Cookies.remove('union_id');
      Cookies.remove('user_nick');
      this.isLoggedIn = false
      window.location.href = 'about:blank'
    },

    async getUserInfo() {
      if (!this.isLoggedIn ) {
        return
      }
      const response = await axios.get('/getuserinfo', {
        params: {union_id: this.union_id}
      })
      if (response.status === 200) {
        const chat_model_name = response.data.chat_model_name
        if (chat_model_name) {
          this.chat_model_name = chat_model_name
          this.chat_model_code = response.data.chat_model_code
        }
      }
    },

    async listFiles(file_id) {
      if (!this.isLoggedIn ) {
        return
      }
      await this.listSpaces();
      if (typeof file_id !== 'undefined') {
        this.folder_id = file_id
      }
      const response = await axios.get('/listfiles', {
        params: {union_id: this.union_id, space_id: this.space_id, folder_id: this.folder_id, keyword: this.keyword}
      })
      if (response.status === 200) {
        const all_files = response.data
        this.folder_list = []
        this.file_list = []
        for (let i = 0; i < all_files.length; i++) {
          const record = all_files[i];
          if (record.file_type === '0') {
            this.file_list.push(record);
          }
          if (record.file_type === '1') {
            this.folder_list.push(record);
          }
        }
      }
    },

    checkLoginAndUpload() {
      // 判断用户是否登录
      if (!this.isLoggedIn) {
        this.wxLogin()
      }

      // 用户已登录，执行文件上传操作
      this.$refs.fileInput.click();
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    async handleDrop(event) {
      // // 判断用户是否登录
      // if (!this.isLoggedIn) {
      //   this.wxLogin()
      // }

      event.preventDefault();

      const files = event.dataTransfer.files;
      this.loading = true;
      for (const file of files) {
        try {
          const formData = new FormData();
          formData.append('union_id', this.union_id);
          formData.append('space_id', this.space_id);
          formData.append('folder_id', this.folder_id);
          formData.append('file_type', 0);
          formData.append('file_name', this.new_folder_name);
          formData.append('file', file);

          const response = await axios.post('/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          });

          if (response.status === 200) {
            this.listFiles();
          } else {
            // 获取返回文本
            const text = response.data;
            // console.error("text:", text)
            alert(text);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
          this.isAddingFolder = false;
        }
      }
    },

    async addFile(file_type) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('union_id', this.union_id);
        formData.append('space_id', this.space_id);
        formData.append('folder_id', this.folder_id);
        formData.append('file_type', file_type);
        formData.append('file_name', this.new_folder_name);

        if (file_type === '0') {
          const file = this.$refs.fileInput.files[0]
          formData.append('file', file);
        }

        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });

        if (response.status === 200) {
          // 上传成功
          this.listFiles();
        } else {
          // 获取返回文本
          const text = response.data;
          // console.error("text:", text)
          alert(text);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.isAddingFolder = false;
      }
    },

    async deleteFile(file_id) {
      try {
        await axios.post('/deletefile', {
          file_id: file_id,
          union_id: this.union_id,
          space_id: this.space_id,
        });
        this.listFiles();
      } catch (error) {
        console.log(error);
      }
    },

    // 切换文件
    async getFileDetails(file_id) {
      this.file_id = file_id
      const response = await axios.get('/getfiledetails', {
        params: {union_id: this.union_id, file_id: file_id}
      })
      // console.error("response:", response.data)
      if (response.status === 200) {
        this.file_link = response.data['file_link']
        this.word_link = response.data['word_link']
        this.pdf_link = response.data['pdf_link']
        this.excel_link = response.data['excel_link']
        this.file_name = response.data['file_name']
        this.summary = response.data['summary'];
        this.default_questions = response.data['questions'];
        this.qa_list = response.data['qa_list'];
        this.agenda = response.data['agenda'];
        this.question_list = [];
        this.selected_text = '';
        this.note = response.data['note'];
        if (!this.note) {
          this.note = '##' + this.file_name
        }
      }
      // console.error("note:", this.note)
    },

    // 切换文件
    clearFileDetails() {
      this.file_id = ''
      this.file_link = ''
      this.word_link = ''
      this.excel_link = ''
      this.file_name = ''
      this.summary = ''
      this.default_questions = ''
      this.qa_list = []
      this.question_list = [];
      this.selected_text = ''
      this.selected_text_result = []
      this.speak_url = ''
      this.phonetic = ''
      this.selected_text_summary = ''
      this.selected_text_explain = ''
      this.need_manual_translate = false
      this.note = ''
    },

    async ask_default_question(question) {
      this.question = question;
      await this.askDoc(0);
      this.selected_text = ''
    },

    async ask_question() {
      await this.askDoc();
      this.selected_text = ''
    },

    onInput(event) {
      this.selected_text = event.target.value;
    },

    // 对选中文本生成摘要
    async selected_summary() {
      const content = this.selected_text
      await this.askDoc(2, "对内容生成摘要", content)
      this.selected_text_summary = this.answer.content;
    },

    // 对选中文本进行问答
    async selected_ask(prompt) {
      const content = this.selected_text
      await this.askDoc(1, prompt + "：" + content)
      // console.error("this.answer:", this.answer)
      this.selected_text_explain = this.answer['content'];
      this.scrollToBottomAndCenter()
    },

    // scope:0-全文内容问答，1-检索匹配后问答，2-指定内容问答
    async askDoc(scope = '', question = '', content = '') {
      if (!this.isLoggedIn) {
        alert("请登录后使用。");
        return
      }
      if (scope === '') {
        scope = this.scope
      }
      if (question === '') {
        question = this.question;
      }
      const jsonData =
          {
            'file_id': this.file_id,
            'union_id': this.union_id,
            'space_id': this.space_id,
            'qa_list': this.qa_list,
            'question': question,
            'scope': scope,
            'content': content,
            'chat_model_code': this.chat_model_code
          }
      this.loading = true;

      try {
        const response = await axios.post('/askdoc', jsonData, {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 120000
        })
        const answer = response.data
        this.answer = answer;
        this.question_list.push(question);
        // 更新问答历史记录列表
        this.qa_list.push({
          question: question,
          answer: answer,
        });
        // console.error("qa_list:", this.qa_list)
        this.question = ''
        const textarea = this.$refs.textarea;
        textarea.style.height = "2.5em"; // 设置为一行的高度
      } catch (error) {
        console.error(error);
      } finally {
        // 设置loading状态为false
        this.loading = false;
        this.scrollToBottomAndCenter()
      }
    },




    async goToFile(paragraph) {
      const file_id = paragraph.file_id
      const response = await axios.get('/getfiledetails', {
        params: {union_id: this.union_id, file_id: file_id}
      })
      const file_link = response.data.file_link
      const url = btoa(file_link)
      var preview_url = 'https://server.jiuzhiai.cn:8088/onlinePreview?url=' + encodeURIComponent(url)
          + '&officePreviewType=pdf'
      window.open(preview_url);
    },

    handleEnter(event) {
      if (event.shiftKey) {
        this.question += '\n';
      } else {
        this.askDoc(1);
      }
    },

    scrollToBottomAndCenter() {
      const parentElement = document.getElementById('qacontent');
      if (parentElement && parentElement.id === 'qacontent') {
        parentElement.scrollTo({
          top: parentElement.scrollHeight,
          bottom: 0,
          behavior: 'smooth'
        });
      }
    },
  },

};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.function-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  padding: 0 16px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.app-main {
  display: flex;
  width: 100%;
  flex: 1;
  background-color: #f5f5f5;
  padding-top: 5px;
  overflow: hidden;
  height: 80%;
}

.dialog-wrapper {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.dialog-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.folder_name_input {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.name_label {
  font-size: 14px;
  margin-bottom: 10px;
}

.img_wxminip {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_wx {
  width: 300px;
}

.input_field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.cancel-button,
.submit-button {
  margin-left: 10px;
  margin-right: 10px;
  font-size: x-small;
  width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
}

.cancel-button {
  background-color: #ccc;
}

.submit-button {
  background-color: #409eff;
  color: #fff;
}

.feedback-content {
  width: 95%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  margin-bottom: 10px;
}

.feedback-contact {
  width: 95%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.logo {
  display: flex;
}

.logo img {
  height: 80px;
  margin-right: 8px;
}

.function-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.link {
  padding-right: 15px;
}

.horizontal-line {
  border: none;
  border-top: 1px solid #cccccc;
  width: 70%;
  /*margin: 10px 0;*/
}

.file-horizontal-line {
  border: none;
  border-top: 1px solid #cccccc;
  width: 95%;
}

.space_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.space_name_text {
  font-size: 14px;
  color: black;
  margin-right: 10px;
  flex-grow: 1;
}


.space_actions {
  font-size: 12px;
  color: gray;
}


.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
}

.el-icon--right {
  height: 20px;
  cursor: pointer;
}

.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* 白色背景，透明度为 80% */
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.dialog img {
  max-width: 50%;
  max-height: 50%;
  padding: 10px;
}

.router_link {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.router_link_a {
  pointer-events: none;
  color: #000;
  text-decoration: none;
}

.function-bar h1 {
  font-size: 24px;
  font-weight: normal;
  margin-left: 10px;
}

.login button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.user-info span {
  font-size: 16px;
}

.left-panel-container {
  width: 15%;
  height: 100%;
}

.file-list-container {
  display: flex;
  flex-direction: column;
  /*overflow-y: ;*/
  background-color: #fff;
  width: 90%;
  height: 98%;
  padding: 10px;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-right: 1px solid #ccc;
}

.file-list-container-indent {
  width: 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  padding: 5px 0px;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-right: 1px solid #ccc;
}

.indent-button {
  cursor: pointer;
}

.file-list-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*padding-bottom: 10px;*/
}

.file-title {
  flex: 1;
  font-weight: normal;
}

.add-folder-icon {
  padding-right: 5px;
  cursor: pointer;
}

.function-name {
  font-weight: lighter;
  font-size: smaller;
  text-decoration: underline;
  padding-right: 5px;
  cursor: pointer;
}


.middle-area {
  background-color: #f8f8f8;
  width: 55%;
  height: 99%;
}


.middle-area.expand {
  width: 65%;
}

.file-reader {
  /*overflow-y: auto;*/
  background-color: #f8f8f8;
  height: 100%;
}

.file-info-area {
  height: 98%;
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 2fr));
  gap: 0.1rem;
  padding: 0.1rem;
  overflow-y: auto; /* 添加此行 */
}

.file-info {
  background-color: #f5f5f5;
  padding: 1rem;
  /*width: 99%;*/
  border-radius: 4px;
}

.file-name {
  /*display: flex;*/
  /*align-items: center;*/
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
  word-break: break-all;
}

.file-name > .el-icon {
  margin-right: 0.5rem;
}

.file-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-date {
  font-size: small;
}

.file-dropdown .el-dropdown-link {
  color: inherit;
}

.folder-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*margin: 10px;*/
  width: 95%;
  cursor: pointer;
}

.folder-name {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-weight: normal;
  font-size: medium;
  margin: 0px 5px 5px 5px;
}


.folder-name > .el-icon {
  margin-right: 0.5rem;
}

.folder-list {
  width: 100%;
  max-height: 70%;
  overflow: auto;
  /*flex: 1;*/
  /*scroll-behavior: auto;*/
  /*border: 2px solid #000; !* 黑色实线边框 *!*/
  padding-bottom: 10px;
}



.agenda-info-1 {
  font-weight: normal;
}

.agenda-info-2 {
  margin-left: 10px;
}

.agenda-info-3 {
  margin-left: 20px;
}

.agenda-info-4 {
  margin-left: 25px;
}

.agenda-info-5 {
  margin-left: 30px;
}

.agenda-name {
  font-size: small;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
}

.drag-drop-area {
  /*width: 300px;*/
  /*height: 80%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.drag-drop-area p {
  font-size: 16px;
  color: #666;
}

.file-upload-area {
  margin-top: 15px;
  /*position: fixed;*/
  /*z-index: 1;*/
  margin-bottom: 15px;
  height: 20%;
}

.file-upload{
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: center;
  align-items: center;
}

.file-upload input[type='file'] {
  display: none;
}


.file-upload button {
  font-size: smaller;
  background-color: #409eff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 5px 5px;
  margin-top: 10px;
  transition: background-color 0.2s ease-in-out;
}



.right_panel {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 35%;
  height: 100%;
  align-items: flex-start;
}

.right_panel_file {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.interact-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: flex-start;
  overflow-y: auto;
  height: 80%;
  width: 100%;
}

.interact-container-note {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*overflow-y: auto;*/
  background-color: #fff;
  /*width: 100%;*/
  height: 90%;
  width: 100%;
  align-items: center;
  padding-bottom: 60%;
}


.file-note {
  /*flex: 1;*/
  overflow-y: auto;
  width: 95%;
  /*height: 100%;*/
  /*padding: 2px;*/
  padding-bottom: 10%;
}

.qa-content {
  flex: 1;
  /*overflow-y: auto;*/
  width: 95%;
  height: 100%;
  /*padding: 2px;*/
  padding-bottom: 10%;
}

.file_action {
  display: flex;
  flex-direction: row;
  font-size: small;
  justify-content: center;
}

.tips_title {
  padding: 5px;
  font-weight: normal;
}

.link_action {
  padding: 5px;
  font-weight: normal;
  font-size: small;
}

.file_summary {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: center;
}

.default_question_bar {
  padding: 1px 5px;
  /*text-decoration: none;*/
  /*border-bottom: 1px solid #ccc;*/
  /*cursor: pointer;*/
  font-weight: lighter;
  /*display: inline-block;*/
  margin-bottom: 5px;
}

.qa-item {
  margin-bottom: 10px;
}

.qa-question {
  /*padding: 5px;*/
  font-weight: normal;
  white-space: pre-wrap;
  line-height: 1.5;
}

.qa-answer {
  padding: 5px;
  font-weight: lighter;
  white-space: pre-wrap;
  line-height: 1.5;
  background-color: #f5f5f5;
}

.qa-answer-content {
  padding-bottom: 5px;
}

.selection-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f2f2f2;
  height: 50%;
  width: 24%;
  font-size: small;
  position: fixed;
  bottom: 20%;
}


.selection-area-bar {
  padding-bottom: 5px;
}

.selected-text-area {
  font-size: small;
  font-family: "Microsoft YaHei";
  width: 98%;
  min-height: 20%;
  height: 30%;
  overflow-y: auto;
}

.speak-url {
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phonetic {
  padding: 1px 5px;
  /*padding-right: 5px;*/
  align-items: center;
  cursor: pointer;
}
.selected-text-result_area {
  font-size: small;
  overflow-y: auto;
  width: 98%;
  height: 60%;
  max-height: 60%;
  font-family: "Microsoft YaHei";
}

.selected-text-result {
  padding: 1px 1px;
  font-size: small;
  width: 98%;
  font-family: "Microsoft YaHei";
}


.qa-input-area {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 24%;
  height: 15%;
  position: fixed;
  padding: 10px;
  bottom: 0px;
  /*z-index: 1;*/
}

.action-scope {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-small;
  padding-left: 5px;
}

.qa-scope {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-small;
}

.qa-input-bar {
  padding-top: 1px;
  display: flex;
}

.qa-input {
  width: 100%;
  height: 2.5em;
  font-size: large;
  font-weight: lighter;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.qa-send-btn {
  font-size: smaller;
  margin-left: 5px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.loading-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
  animation: loading-spinner 0.8s ease-in-out infinite;
}

@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}

.reference-list {
  display: flex;
  flex-wrap: nowrap;
  /*overflow-x: auto;*/
  background-color: #cccccc;
  font-size: small;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 }

.reference-list ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reference-list li {
  margin-right: 15px;
  /*padding-left: 5px;*/
}

.reference-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.indent-tooltip {
  position: absolute;
  font-size: xx-small;
  top: 15%;
  left: 15%;
  /*transform: translateX(-50%);*/
  z-index: 9999;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.scope-tooltip {
  position: absolute;
  top: 35%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tooltip {
  position: absolute;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 12px;
  font-weight: normal;
  width: 50%;
  white-space: pre-wrap;
  z-index: 9999;
  top: calc(50% - 50px);
  left: 25%;
}


.intro-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-weight: lighter;
}

.intro-container > div {
  width: 400px;
  height: 40px;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: all 0.3s ease;
}


.intro1 {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  justify-content: left;
  align-items: center;
  word-wrap: normal;
}

.intro2 {
  grid-column: 2 / 2;
  grid-row: 1 / 3;
  justify-content: left;
  align-items: center;
}

.intro3 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-content: left;
  align-items: center;
}

.intro4 {
  grid-column: 2 / 2;
  grid-row: 2 / 3;
  justify-content: left;
  align-items: center;
}

.intro5 {
  grid-column: 1 / span 2;
  grid-row: 3 / 3;
  justify-content: left;
  align-items: center;
}

</style>
