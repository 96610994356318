<template>
<!--    <router-link to="/">Home</router-link>-->
<!--    <router-link to="/chat">Chat</router-link>-->
    <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  created() {
    document.title = "久知智能文档"
  },
  methods: {
    goToChat() {
      this.$router.push('/chat');
    },
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif;
  background-color: #f7f7f7;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

</style>
